import React, {  useState,useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";
import materialInfo from "../Data.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


const Products = () => {
  
  const [IsOpen, setIsOpen] = useState(false);
  const [pageHeight, setPageHeight] = useState('block');
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedText, setSelectedText] = useState("");
  const [selectedID, setSelectedID] = useState("");

  const scrollUp = () => {
    setPageHeight('block');
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const showDialog = (image, text, id) => {
    console.log("clicked");
    setPageHeight('none');
    setSelectedImage(image);
    setSelectedText(text);
    setSelectedID(id)
    setIsOpen(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const hide = () => {
    setIsOpen(false);
    setPageHeight('block');
  };

  const product = materialInfo.find((product) => product.id === selectedID);

  const nextImage = () => {
    
    const currentIndex = product.image.findIndex((image) => image.img === selectedImage);
    const nextIndex = (currentIndex + 1) % product.image.length;
    const nextImage = product.image[nextIndex].img;
    const nextText = product.image[nextIndex].text;
    setSelectedImage(nextImage);
    setSelectedText(nextText || '')
  };
  const previousImage = () => {
    const currentIndex = product.image.findIndex((image) => image.img === selectedImage);
    const previousIndex = (currentIndex - 1 + product.image.length) % product.image.length;
    const previousImage = product.image[previousIndex].img;
    const previousText = product.image[previousIndex].text;
    setSelectedImage(previousImage);
    setSelectedText(previousText || '')
  };
  
  const [mobile, setMobile] = useState(true);
  useEffect(() => {
    window.innerWidth <= 768 ? setMobile(true) : setMobile(false);
  }, [mobile]);
  
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Container style={{display:pageHeight}}>
          <h1 style={{ fontSize: 60 }}>Products</h1>
          <MaterialList>
            {materialInfo.map((i) => (
              <Link to={`/product/${i.id}`}>
                <Circle>{i.name}</Circle>
              </Link>
            ))}
          </MaterialList>
          <Row className="justify-content-md-start" style={{ margin: "auto" }}>
            {materialInfo.map((i) => (
              <Col lg={4} key={i._id} className='column'>
                <Box>
                  <ImageContainer>
                    <InnerSkew>
                      <Carousel>
                        {i.image.map((x) => (
                          <div onClick={() => showDialog(x.img, x.text, i.id)}>
                            <img src={x.img} alt="" key={x.ind} />
                          </div>
                        ))}
                      </Carousel>
                    </InnerSkew>
                  </ImageContainer>

                  <TextContainer className="color-primary">
                    <h3>
                      <Link to={`/product/${i.id}`}>
                        {i.name} ({i.fullform})
                      </Link>
                    </h3>
                    <ViewDetails>
                      <Link onClick={scrollUp} to={`/product/${i.id}`}>View Details</Link>
                      </ViewDetails>
                  </TextContainer>
                </Box>
              </Col>
            ))}
          </Row>
        </Container>
        {IsOpen && (
          <Popup>
            <Close onClick={hide}>X</Close>
            {mobile 
            ?   
            <div>
            <ImageModal>
            <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={selectedImage} alt="" /> 
        </motion.div>
        
            </ImageModal>
            <div className="imageSection">
            {product.image.length > 1 ? 
              <Previous onClick={previousImage}>&lt;</Previous>
               : 
               ''
              }
            {product.image.length > 1 ? 
            <Next onClick={nextImage}>&gt;</Next>
             : 
             ''
              }
              </div>
              </div>

:


<div className="imageSection">
              {product.image.length > 1 ? 
              <Previous onClick={previousImage}>&lt;</Previous>
               : 
               ''
              }
            
            <ImageModal>
            <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <img src={selectedImage} alt="" /> 
        </motion.div>
            </ImageModal>
            {product.image.length > 1 ? 
            <Next onClick={nextImage}>&gt;</Next>
             : 
             ''
              }
            
            </div>
            }
            
            <h1>{selectedText}</h1>
            <ViewDetails style={{margin: 'auto'}}>
              <Link onClick={scrollUp} to={`/product/${selectedID}`}>View Details</Link>
            </ViewDetails>
          </Popup>
        )}
      </motion.div>
    </>
  );
};

const Container = styled.div`
  h1 {
    text-align: center;
  }
`;

const MaterialList = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 100px 0 30px 0;
  @media(max-width:768px){
    margin: 50px 0 30px 0;
  }
  a {
    text-decoration: none;
  }
`;
const Circle = styled.div`
  font-weight: 600;
  font-size: large;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #ffffff;
  color: #191e28;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
`;

const Box = styled.div`
  display: inline-block;
  text-align: center;
`;
const ImageContainer = styled.div`
  height: 350px;
  width: 350px;
  overflow: hidden;
  border-radius: 20px !important;
  display: inline-block;
  @media(max-width:768px){
    width: unset !important;
    height: 300px;
  }
  @media(max-width:330px){
    width: 270px;
    height: 270px;
  }
`;

const InnerSkew = styled.div`
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  // transform: skew(0deg, 13deg);
  font-size: 0px;
  
  background: #c8c2c2;
  img{
    width: 100%;
    @media(max-width:768px){
      width: unset;
    }
  }
  }
`;

const TextContainer = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 14px;
  width: 400px;
  height: 280px;
  @media(max-width:768px){
    width: auto;
  }
  h3 {
    margin: 20px 0px 10px 0px;
    font-size: 25px;
    font-weight: bolder;
    a {
      text-decoration: none;
      color: #191e28;
    }
  }
`;

const Popup = styled.div`
  position: absolute;
  background-color: whitesmoke; 
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  h1 {
    color: #191e28;
    font-size: 25px;
    text-align: center;
    margin: 30px;
    
  }
  @media(max-width:768px){
    height: 600px;
  }
`;

const ImageModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    border-radius: 26px;
    box-shadow: 0 8px 6px -6px black;
    @media(max-width:768px){
      width: 300px;
    height: 300px;
    }
  }
`;

const Close = styled.div`
  font-size: 45px;
  cursor: pointer;
  font-weight: bolder;
  margin: 20px 0 0 30px;
  @media(max-width:768px){
    font-size: 25px;
    }
`;

const ViewDetails = styled.button`
  border: none;
  border-radius: 10px;
  outline: none;
  background-color: #191e28;
  color: white;
  padding: 8px;
  margin: 10px auto 10px ;
  display: block;
  a{
    text-decoration: none;
    color: white;
  }
`;

const Next = styled.button`
background-color: #283048;
color: white;
outline: none;
border: none;
border-radius: 50%;
font-size: 35px;
height: 50px;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
`
const Previous = styled.button`
background-color: #283048;
color: white;
outline: none;
border: none;
border-radius: 50%;
font-size: 35px;
height: 50px;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
`

export default Products;
