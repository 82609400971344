import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from '../Pages/Home'
import Contact from '../Pages/Contact'
import Products from '../Pages/Products'
import ProductDetail from '../Pages/ProductDetail';


const AnimatedRoutes = () => {
    const location = useLocation();
  return (
    <div>
        <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />
        <Route path="contact" element={<Contact />} />
        <Route path="product/:id" element={<ProductDetail />} />
      </Routes>
    </AnimatePresence>
    </div>
  )
}

export default AnimatedRoutes