import React,{ useEffect, useState, useRef } from "react";
import styled from "styled-components";
// import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
// import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import { motion } from "framer-motion";
import emailjs from '@emailjs/browser';


const Contact = () => {

  const form = useRef();
  const [state, setState] = useState({
    email: "",
    name: "",
    number: "",
    companyName: "",
    message: "",
  });

  // const [show,setShow] = useState(false)

  const [errors,setErrors] = useState({});

  const [isSubmit,setSumbit] = useState(false)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value
    }));
  };

 

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validate(state));
    setSumbit(true);
    
  };

  useEffect(() => {
    if(Object.keys(errors).length === 0 && isSubmit){
      emailjs.sendForm('service_yt2ohmd', 'template_juenpkb', form.current, 'XgpZsrhb-sJPakvFY')
      .then((result) => {
        alert('Successfully submitted');
        window.location.href = '/';
        
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      console.log('submitted');
      // setShow(true);
    }
  })
  

  const validate = (values) => {
    const err = {}
    if(!values.name){
      err.name = "Please fill out the input field !"
    }
    if(!values.companyName){
      err.companyName = "Please fill out the input field !"
    }
    if(!values.email){
      err.email = "Please fill out the input field !"
    }
    if(!values.number){
      err.number = "Please fill out the input field !"
    }
    if(!values.message){
      err.message = "Please fill out the input field !"
    }
    return err;
  }
  return (
    <div>
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      
      <Container>
        <h1 style={{fontSize: 60}}>Contact us</h1>
        <form ref={form} className="form" onSubmit={handleSubmit}>
          <p type="Name :">
            <input name="name" placeholder="Write your name here.." value={state.name} onChange={handleInputChange}/>
          </p>
          <p style={{color:'red'}}>{errors.name}</p>
          <p type="Company Name :">
            <input name="companyName" placeholder="Write your company name here.." value={state.companyName} onChange={handleInputChange}/>
          </p>
          <p style={{color:'red'}}>{errors.companyName}</p>
          <p type="Contact Number :">
            <input name="number" placeholder="Write your contact number here.." value={state.number} onChange={handleInputChange}/>
          </p>
          <p style={{color:'red'}}>{errors.number}</p>
          <p type="Email :">
            <input name="email" placeholder="Let us know how to contact you back.." value={state.email} onChange={handleInputChange}/>
          </p>
          <p style={{color:'red'}}>{errors.email}</p>
          <p type="Message :">
            <input name="message" placeholder="What would you like to tell us.." value={state.message} onChange={handleInputChange}/>
          </p>
          <p style={{color:'red'}}>{errors.message}</p>
          
          <button type="submit">Send Message</button>
          {/* {show && 
          <h1 style={{color:'darkgreen',fontStyle:'bolder'}}>Successfully Submitted</h1>
          } */}
          <div className="dflex">
            {/* <LocalPhoneRoundedIcon /> */}
            <span className="fa fa-phone">+91 9323582798</span>
            {/* <EmailRoundedIcon /> */}
            <span className="fa fa-envelope-o">brplastm@gmail.com</span>
          </div>
        </form>
      </Container>
      
      
    </motion.div>
      
    </div>
  );
};

const Container = styled.div`
  .form {
    width: 600px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 40px -10px #000;
    margin: calc(50vh - 280px) auto;
    padding: 20px 30px;
    max-width: calc(100vw - 40px);
    box-sizing: border-box;
    // position: relative;
  }
  h1 {
    text-align: center;
    color: black;
  }
  input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    outline: none;
    resize: none;
    border: 0;
    transition: all 0.3s;
    border-bottom: 2px solid #bebed2;
  }
  input:focus {
    border-bottom: 2px solid #78788c;
  }
  p:before {
    content: attr(type);
    display: block;
    margin: 32px 0 0;
    font-size: 18px;
    color: balck;
  }
  button {
    float: right;
    border-radius: 50px;
    padding: 8px 12px;
    margin: 20px 0;
    border: transparent;
    background: black;
    color: white;
    cursor: pointer;
    transition: all 0.3s;
  }
  // button:hover {
  //   background: #ace0f9;
  //   color: black;
  //   font-size: x-large;
  // }
  div {
    background: #50505a;
    color: #fff;
    padding: 20px;
    border-radius: 6px;
    font-size: 18px;
    box-shadow: 10px 10px 40px -14px #000;
    width: 100%;
    justify-content: space-between;
    @media(max-width:768px){
      flex-direction: column;
    }
  }
  span {
    margin: 0 5px 0 15px;
  }
`;

export default Contact;
