import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import materialInfo from '../Data'

const Dropdown = ({onMouseLeave}) => {
  return (
    <>
        <DropdownContainer onMouseLeave={onMouseLeave}
          >
            {materialInfo.map((data,index)=>(
            <DropdownList>
                <Link key={index} to={data.link}>{data.name}</Link>
            </DropdownList>
            ))}
          </DropdownContainer>
    </>
  )
}

const DropdownContainer = styled.div`
background: #191e28;
color: white;
width: 170px;
height: auto;
border-radius:35px;
padding:20px;
border: transparent;
position: absolute;
top: 110px;
left: 790px;
`
const DropdownList = styled.div`
// background-color: transparent;
font-size: 22px;
text-align: center;
padding:10px 0;
a{
    text-decoration:none;
    color:white;
}
`

export default Dropdown