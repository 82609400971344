import bopp from "./images/bopp.png";
import hm2 from "./images/hm2.webp";
import ld2 from "./images/ld2.jpg";
import pof from "./images/pof.jpg";
// import dangler from "./images/bopp-folder-dangler.jpg";
import bopp1 from "./images/bopp4.jpg";
import bopp2 from "./images/bopp2.jpg";
import pof1 from "./images/pof1.jpg";

const materialInfo = [
  {
    id:1,
    name: "BOPP",
    fullform: "Biaxilly Oriented Polypropylene",
    usage: "Packaging",
    pattern: "Plain/Printed",
    thickness: "50 microns",
    color: "Transparent",
    description:
      "BOPP film is commonly used for applications that require moisture resistance, optical clarity and high tensile strength such as: Food & Beverage Packaging. Medical Packaging. Personal Care Product Packaging. General use.",
    order: "100kg minimum",
    image: [
      {ind:1,img:bopp1,text:'Folder/Dangler for comb and other'},
      {ind:2,img:bopp2},
      {ind:3,img:bopp},
      // bopp1,bopp2,bopp
    ],
    link:'product/1'
  },
  {
    id:2,
    name: "POF",
    fullform: "Polyolefin Shrink Films",
    usage: "Packaging",
    pattern: "Plain/Printed",
    thickness: "19-50 microns",
    color: "Transparent",
    description:
      "POF shrink wrap (also referred to as Shrink film or Shrink wrap) is a versatile polymer material used for the packaging of the finished goods. Heat is applied to the film - by either a conveyor heat tunnel or an electric or gas heat gun - which catalyzes the film to shrink tightly around the item placed within.",
    order: "100kg minimum",
    image: [
      {ind:1,img:pof1,text:'POF shrink pouches'},
      {ind:2,img:pof},
      // pof1,pof
    ],
    link:'product/2'
  },
  {
    id:3,
    name: "LD",
    fullform: "Low Density Polyethylene Plastic",
    usage: "Packaging",
    pattern: "Plain/Printed",
    thickness: "50 microns",
    color: "Transparent/colour",
    description:
      "It is a synthetic high-density polyethylene sheet roll that is a versatile non-woven fabric suitable for various industries including transportation, packaging, and a lot more.",
    order: "100kg minimum",
    image: [
      {ind:1,img:ld2},
      // ld2
    ],
    link:'product/3'
  },
  {
    id:4,
    name: "HM",
    fullform: "High Molecular Plastic",
    usage: "Packaging",
    pattern: "Plain/Printed",
    thickness: "50 microns",
    color: "Transparent",
    description:
      "It is a synthetic high-density polyethylene sheet roll that is a versatile non-woven fabric suitable for various industries including transportation, packaging, and a lot more.",
    order: "100kg minimum",
    image: [
      {ind:1,img:hm2},
      // hm2
    ],
    link:'product/4'
  },
];

export default materialInfo