import React,{useEffect,useState} from "react";
import styled from "styled-components";
import factory from "../images/factory.png";
import Typewriter from 'typewriter-effect';

const Section1 = () => {

  const [mobile, setMobile] = useState(true);
  useEffect(() => {
    window.innerWidth <= 768 ? setMobile(true) : setMobile(false);
  }, [mobile]);
  
  return (
    <div className="color-primary">
      <Container>
        {!mobile ?
        <h1 className="hero-text">Poly Bags <Typewriter
        options={{
          autoStart: true,
          loop: true,
        }}
  onInit={(typewriter) => {
    typewriter.typeString('Manufacturer in India')
      .pauseFor(1500)
      .deleteAll()
      .typeString('Trader in India')
      .start();
  }}
/></h1> : <h1 className="hero-text" style={{fontSize:'larger'}}>Poly Bags Manufacturer in India</h1>}
        
        <img src={factory} alt="" />
      </Container>
    </div>
  );
};

const Container = styled.div`
  // background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  height: 450px;
  display: flex;
  align-items: center;
  @media(max-width:786px){
    height:100%;
    flex-direction:column-reverse;
  }
  h1 {
    margin: 0;
    padding-left: 150px;
    @media (max-width:786px){
    margin: 30px;
    padding:0;
    }
  }
  img {
    height: 400px;
    position: absolute;
    right: 25px;
    top: 140px;
    @media (max-width:786px){
      height: 200px;
      margin: auto;
      display: block;
      position:unset;
    }
  }
`;

export default Section1;
