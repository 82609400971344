import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import materialInfo from "../Data.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useParams } from "react-router-dom";

const ProductDetail = () => {
    const params = useParams();
    const prodId = params.id;
    const thisProduct = materialInfo.find(prod => prod.id === parseInt(prodId)) // eslint-disable-next-line eqeqeq
    // console.log(thisProduct);

    if (thisProduct) {
      // do something with thisProduct
      console.log(thisProduct.image);
    } else {
      console.log(`Product with id ${prodId} not found`);
    }
    // const images = thisProduct.image.map((i) => (i));
    // console.log(images)

  return (
    <>
      <motion.div
        // className="container text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Container>
          <h1 style={{fontSize: 60, paddingLeft:20,paddingRight:20}}>Product Detail</h1>
            <Box key={prodId}>
                
                  <ImageContainer>
                    <InnerSkew>
                      <Carousel>
                        {thisProduct.image.map((i) => <img  src={(i.img)} alt="" key={i.ind}/>)}
                          
                        
                      </Carousel>
                    </InnerSkew>
                  </ImageContainer>
                  
                
                
          <TextContainer>
                    <h3 className="color-primary">
                      {thisProduct.name} ({thisProduct.fullform})
                    </h3>

                    <Description>
                      <h5>{thisProduct.description}</h5>
                    </Description>
                    <div className="dflex spaceAround">
                      <Specification>
                        <p className="Usage">Usage </p>
                        <p className="Pattern">Pattern </p>
                        <p className="Thickness">Thickness </p>
                        <p className="Color">Color </p>
                        <p className="Order">Order Quantity </p>
                      </Specification>
                      <Specification>
                        <p className="Usage">: {thisProduct.usage}</p>
                        <p className="Pattern">: {thisProduct.pattern}</p>
                        <p className="Thickness">: {thisProduct.thickness}</p>
                        <p className="Color">: {thisProduct.color}</p>
                        <p className="Order">: {thisProduct.order}</p>
                      </Specification>
                    </div>
                  </TextContainer>
                  
                
              
            </Box>
          
        </Container>
      </motion.div>
    </>
  );
};

const Container = styled.div`
  h1 {
    text-align: center;
  }
`;

const Box = styled.div`
display:flex;
align-items:center;
justify-content:space-evenly;
margin:50px;
@media(max-width:768px){
  flex-direction:column;
  margin: 30px;
}
`;
const ImageContainer = styled.div`
height: 480px;
width: 420px;
overflow: hidden;
border-radius: 0px 0px 20px 20px;
display: inline-block;
@media(max-width:768px){
  height: 300px;
  width: 300px;
  margin: auto;
  display: block;
}
@media (max-width: 768px){
  width: 270px;
}

`;

const InnerSkew = styled.div`
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 420px;
  width: 420px;
  img{
    width: 100%;
    // height: 100%;
  }
  @media(max-width:768px){
    height: 100%;
    width: 100%;
  }
  }
`;

const TextContainer = styled.div`
height: 480px;
width: 654px;
border-radius: 20px;
box-shadow: 0px 14px 32px 0px rgba(0, 0, 0, 0.75);
  line-height: 19px;
  font-size: 14px;
  padding:20px;
  h3 {
    margin: 20px 0px 10px 0px;
    font-size: 25px;
    font-weight: bolder;
  }
  @media(max-width:768px){
    height: 100%;
    width: 100%;
    margin: 22px 0;
  }
`;

const Description = styled.p`
  h5 {
    margin: 40px 0;
    line-height: 28px;
    font-size: larger;
  }
`;

const Specification = styled.p`
  p {
    // font-weight: bold;
    letter-spacing: 1px;
    font-size: large;
    text-align: start;
    @media(max-width:340px){
      font-size: 15px;
    }
  }
`;

export default ProductDetail;
