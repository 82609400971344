import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import AnimatedRoutes from './Layout/AnimatedRoutes';


function App() {
  return (
    <div className='app'>
    <Navbar/>
    <AnimatedRoutes/>
    <Footer/>
    </div>
  );
}

export default App;
