import React from "react";
import styled from "styled-components";


const Section2 = () => {
  return (
    <>
      <Container>
        <h2 className="sub-hero-text">What do we do ?</h2>
        <p>
        As a plastic manufacturing and trading company, we specialize in providing high-quality packaging solutions to meet the diverse needs of our customers. Our products are designed to be durable, versatile, and visually appealing, making them ideal for a wide range of industries and applications. Whether you need custom-designed pouches or off-the-shelf options, we can help you find the perfect solution to meet your specific requirements.
        </p>
      </Container>
    </>
  );
};

const Container = styled.div`
  // background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  background-color: #191e28;
    color: white;
  height: 300px;
  display: block;
  padding: 100px 50px;
  @media(max-width:768px){
    height: auto;
    padding: 50px;
  }
  p{
    letter-spacing: 0.5px;
    margin-top:20px;
  }
`;
export default Section2;
